

























import { defineComponent, reactive, ref } from '@vue/composition-api';
import { useAuth } from '@/admin/auth';
import { useNotification } from '@/composition/notification';

export default defineComponent({
  name: 'AccountPassword',
  setup() {
    const auth = useAuth();
    const notification = useNotification();

    const form = reactive({
      nowPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    });
    const isUpdating = ref(false);

    const update = async () => {
      if (!form.newPasswordConfirm || !form.newPassword || !form.newPasswordConfirm) {
        notification.error('全ての項目を入力してください');
        return;
      }
      if (form.newPassword !== form.newPasswordConfirm) {
        notification.error('新しいパスワードが一致しません');
        return;
      }

      isUpdating.value = true;
      try {
        await auth.updatePassword(form.nowPassword, form.newPassword);
        notification.notify('パスワードを変更しました');
      } catch(error) {
        notification.error(error);
      }
      isUpdating.value = false;
    }

    return { form, isUpdating, update };
  }
});
